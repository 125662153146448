import { Link } from "gatsby"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Page404: Not found" />

    <h1>Hmm... This page doesn't exist</h1>
    <p>
      Head to the{" "}
      <Link
        to="/"
        style={{
          fontSize: `var(--font-sm)`,
          textDecoration: `none`,
        }}
      >
        main page
      </Link>{" "}
      to continue on.
    </p>
  </Layout>
)

export default NotFoundPage
